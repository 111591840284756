// .btn:not(.btn-icon):not(.btn-auto) {
//   min-width: 100px;
//   &.btn-primary {
//     color: white;
//   }
// }
.btn-menu-link {
  padding: 0;
  height: 21px;
  width: 100%;
  text-align: left;
}
