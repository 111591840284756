.btn-custom-selection {
  text-align: left;
  display: inline-flex;
  align-items: center;
  min-width: 80px;
  justify-content: space-between;
}
.custom-selection {
  z-index: 9;
  overflow-y: auto;
  max-height: 300px;
  min-width: 80px;
  &.list-group {
    > .list-group-item:not(.search-box) {
      border-bottom: 0;
      cursor: pointer;
      &:hover {
        background-color: $hover;
      }
      &.active {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
  .search-box {
    position: sticky;
    top: 0;
    .search-box-icon {
      right: 24px;
    }
  }
}

.custom-control {
  .btn-custom-selection {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border: 1px solid $border-color;
    border-radius: 0.25rem;
  }
}
