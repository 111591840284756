.card_group {
  display: flex;
  padding: 0 0.5rem;
  width: 100%;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  .card_groupitems {
    flex: 1;
  }
  .ant-card-body {
    padding: 24px !important;
  }

  .card_content {
    height: 96px;
    transition: all 300ms;
    cursor: pointer;
    &.active,
    &:hover {
      box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
    }
    &.ALL.active,
    &.ALL:hover {
      border-color: $all-case;
    }
    &.NEW_CASE.active,
    &.NEW_CASE:hover {
      border-color: $new-case;
    }
    &.OPEN_CASE_EXCEED_72H.active,
    &.OPEN_CASE_EXCEED_72H:hover {
      border-color: $open-72h-case;
    }
    &.OPEN_CASE_EXCEED_24H.active,
    &.OPEN_CASE_EXCEED_24H:hover {
      border-color: $open-24h-case;
    }
    &.RESOLVED_CASE.active,
    &.RESOLVED_CASE:hover {
      border-color: $resolved-case;
    }
  }

  .desc {
    font-size: 14px;
    color: rgb(185, 182, 182);
  }

  .case-info {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.5rem;
    > span {
      padding-left: 0.25rem;
    }
  }
  .icon {
    background-color: #0000001c;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 50px;
    > span {
      font-size: 2rem;
    }
  }
}

.ant-card.thirdparty-user {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  width: 40%;
  margin: 0 auto;
  .ant-card-body {
    padding: 0 24px;
  }
}
