nav.navbar-sidebar {
  width: $header;
  height: 100vh;
  flex-flow: column nowrap;
  justify-content: flex-start;
  // box-shadow: 0 85px 2px rgba(0, 0, 0, 0.18), 0 80px 8px rgba(0, 0, 0, 0.1);
  border-right: 1px solid $border-color;
  > .navbar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $header;
    width: 100%;
    margin-bottom: 1rem;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: white;
      background-color: $gray-900;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  > .navbar-nav {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    > .navbar-nav-item {
      height: 50px;
      width: 100%;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: $body-color;
      margin-bottom: 1rem;
      &.active {
        background-color: #e6f7ff;
      }
    }
  }
}
