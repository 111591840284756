.float-label {
  position: relative;
  margin-bottom: 3px;
  .ant-select .ant-select-selector {
    height: 28px;
    .ant-select-selection-item {
      padding-top: 2px;
    }
    .ant-select-selection-search {
      left: 0;
    }
  }
  .ant-select .ant-select-selector,
  .ant-input-password,
  .ant-input {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    box-shadow: none !important;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .label {
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 2px;
    transition: 0.2s ease all;
    z-index: 1;

    &.label-float {
      top: -12px;
      font-size: 0.75rem;
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
    .ant-input {
      color: rgba(0, 0, 0, 1);
      background: transparent;
    }

    .ant-select .ant-select-selector {
      background: transparent !important;
      color: rgba(0, 0, 0, 1) !important;
    }
  }
}

.float-label .ant-input:-webkit-autofill ~ label {
  top: -12px;
  font-size: 0.75rem;
}

.ant-form-item-explain {
  font-size: 0.875rem;
  line-height: 1.2;
  position: absolute;
  left: 0;
  bottom: -24px;
}

.ant-form .ant-form-item {
  margin-bottom: 32px;
  margin-top: 16px;
  &.item-inline {
    margin-top: 0;
  }
}
