.swatch-color {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px gainsboro;
  display: inline-block;
  cursor: pointer;
  .pickered-color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }
}
