$nav: 140px;
$nav-closed: 86px;
$nav-thirdparty-workspace: 72px;

.case-nav {
  height: $nav;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  &.minimize,
  &.closed {
    height: $nav-closed;
  }
  &.thirdparty-workspace {
    height: $nav-thirdparty-workspace;
  }
  > .ant-page-header {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0;
    .anticon.anticon-arrow-left svg {
      font-size: 20px;
    }
    .ant-page-header-heading-title {
      font-size: 20px;
      font-weight: normal;
    }
  }

  .case-extra-control {
    padding: 0 16px;
    position: absolute;
    top: 50%;
    right: 0;
    height: 100%;
    transform: translateY(-50%);
  }
}

.case-content {
  height: calc(100% - #{$nav});
  display: flex;
  padding: 16px;
  gap: 16px;
  .ant-card {
    > .ant-card-head {
      padding: 20px 20px 16px 20px;
      border-bottom: 0;
      .ant-card-head-title {
        padding: 0;
        font-size: 20px;
      }
    }
    > .ant-card-body {
      padding: 0 20px 20px 20px;
    }
  }

  > .guides,
  > .workspace {
    flex: 0 0 400px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .thirdparty-workspace {
      overflow: hidden;
      height: calc(100% - 218px);
      .ant-card {
        height: 100%;
        width: 100%;
        > .ant-card-body {
          overflow-y: auto;
          overflow-x: hidden;
          height: calc(100% - 66px);
          position: relative;
        }
      }
    }
    .customer-workspace {
      height: 202px;
    }
  }
  > .guides {
    flex: 0 0 500px;
    width: 500px;

    p {
      margin-bottom: 6px;
    }
  }
  > .documents {
    flex: 1;
    width: calc(100% - 516px);

    .ant-table-cell:nth-child(5) {
      text-align: end;
    }
    .ant-table-row > .ant-table-cell {
      &:not(:last-child) {
        vertical-align: top !important;
      }
      &:last-child {
        text-align: end;
      }
    }
    .ant-popover {
      width: 100% !important;
    }
    .ant-table-body {
      height: calc(100vh - 350px);
    }
  }
  .ant-empty.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
  }
  .case-closed {
    height: 60px;
    width: 100%;
    padding: 20px;
    background-color: #fff1f0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    border: 1px solid #ffccc7;
    color: #f5222d;
  }

  &.thirdparty-workspace {
    height: calc(100% - #{$nav-thirdparty-workspace});
    > .documents {
      width: calc(100% - 400px);
      .ant-table-body {
        height: auto;
        max-height: calc(100vh - 350px) !important;
      }
    }
    > .guides {
      flex: 0 0 400px;
      width: 400px;
    }
  }
  &.customer-workspace {
    height: calc(100% - #{$nav-thirdparty-workspace});
    > .guides {
      flex: 0 0 400px;
      width: 400px;
    }
    > .documents {
      width: calc(100% - 400px);
      .e-request.ant-card {
        height: 80px;
        width: 100%;
        background-color: $primary;
        color: white;
        .sign-in {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
        > .ant-card-body {
          padding: 16px 20px;
        }
      }
      .ant-table-body {
        height: auto;
        max-height: calc(100vh - 398px) !important;
      }
    }
  }
  &.minimize {
    height: calc(100% - #{$nav-closed});
    > .documents {
      height: 100%;
      .ant-table-body {
        height: calc(100vh - 295px);
      }
    }
  }

  &.closed {
    height: calc(100% - #{$nav-closed});
    flex-direction: column;
    > .documents {
      width: 100%;
      height: calc(100% - 76px);
      .ant-table-body {
        height: calc(100vh - 412px);
      }
    }
  }
}

.workspace-item {
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: $grey-2;
  min-height: 105px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .workspace-item--title {
    font-size: 16px;
    font-weight: 500px;
    color: $primary;
  }
  .workspace-item--info {
    font-size: 12px;
    color: $secondary-color;
    margin-top: auto;
  }
}

.document-item {
  .ant-tag.document-tag {
    display: inline-flex;
    align-items: center;
    > a,
    > span {
      color: $primary;
      word-break: break-all;
      white-space: normal;
    }
  }
  > .title {
    display: flex;
    margin-bottom: 3px;
    align-items: center;
    &.uploaded {
      position: relative;
      padding-left: 12px;
      &:before {
        content: " ";
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 50%;
        background-color: #8fd460;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }

      &.un-read::before {
        font-weight: bold;
        background-color: #f5222d;
      }
      &.esigned {
        padding-left: 0;
      }
      &.esigned::before {
        content: none;
      }
    }
  }
  > .ant-btn.upload {
    margin-left: -15px;
    margin-right: -15px;
    .upload--desc {
      margin-left: 6px;
    }
    color: #afb4be;
    display: flex;
    align-items: center;
  }
}
