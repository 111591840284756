.login-card {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  background: #0b1345;
  > div {
    flex: 1;
    &.login-cover {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      > svg {
        width: 80%;
      }
      .app-info {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2% 12%;

        > .app-info--bototm {
          width: 75%;
          color: white;
          font-size: 0.875rem;
          h4 {
            color: white;
          }
        }
      }
    }
    &.login-panel {
      background-color: white;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      display: flex;
      > .login-panel-content {
        min-width: 300px;
        margin: 0 auto;
        margin-top: 20vh;
      }
      .sub-title {
        max-width: 300px;
      }
      .login-form {
        max-width: 300px;
      }
      .login-form-forgot {
        float: right;
      }
      .ant-col-rtl .login-form-forgot {
        float: left;
      }
      .login-form-button {
        width: 100%;
      }
    }
  }
}
