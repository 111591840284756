@import "./components/header";
@import "./components/user-control";
@import "./components/sidebar";
@import "./components/layout";
@import "./components/login";
@import "./components/notification-item";
@import "./components/table";
@import "./components/custom-selection";
@import "./components/search-box";
@import "./components/card";
@import "./components/form";
@import "./components/color-picker";
@import "./components/floating-label";

@import "./pages/home";
@import "./pages/admin";
@import "./pages/case";

@import "./custom-bootstrap/button";
@import "./custom-bootstrap/popover";
@import "./custom-bootstrap/dropdown";
@import "./custom-bootstrap/modal";
@import "./custom-bootstrap/link";

html {
  font-size: 14px;
  min-width: 1200px;
}

body {
  min-width: 960px !important;
}

.heading {
  color: $body-color;
  font-size: 20px;

  &.guide-heading {
    padding-bottom: 16px;
    color: rgba($color: #000000, $alpha: 0.85);
    font-weight: 500;
  }
}

.primary-color {
  color: $primary;
}

.selectable {
  cursor: pointer;
}
