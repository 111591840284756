.ant-dropdown-menu-items {
  padding: 0 1rem;
  .ant-list-item {
    border-bottom: 1px solid #f0f0f0;
  }
}

.ant-message-notice {
  > .ant-message-notice-content {
    > .ant-message-custom-content {
      display: flex;
      align-items: center;
      .on-custom-content {
        display: inline-flex;
        align-items: center;
        justify-content: space-around;
        width: 320px;
        button.close {
          margin-left: auto;
          font-size: 12px;
          color: #888e9c;
        }
      }
    }
  }

  &.on-success {
    > .ant-message-notice-content {
      background-color: #f6ffed;
      border: 1px solid #b7eb8f;
      padding: 6px 16px;
    }
  }
  &.on-error {
    > .ant-message-notice-content {
      background-color: #fff1f0;
      border: 1px solid #ffccc7;
      padding: 6px 16px;
    }
  }
}

.ant-spin.custom {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.65);
}

.ant-list.documents {
  max-height: 350px;
  overflow-y: auto;
  .ant-list-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    border: 0;
  }
  .ant-list-item {
    border-bottom: 0;
    background-color: #f9fafb;
    padding: 12px;
    margin: 12px 0;
  }
}

.ant-divider-horizontal {
  margin: 16px 0;
}

.table-menu {
  align-items: flex-start;
  .title {
    flex: 1 1 0%;
    white-space: nowrap;
    line-height: 1.5715;
    padding: 4px 8px;
  }
  .ant-btn {
    padding: 4px 8px;
    &.min {
      white-space: normal;
      min-width: 150px;
      height: auto;
    }
  }
}

.ant-avatar.ant-avatar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-spin.spiner-custom {
  display: inline-flex;
  .ant-spin-dot-item {
    background-color: white;
  }
}

.ant-list-item-meta.bank-method {
  flex-direction: column;
  .ant-list-item-meta-avatar {
    margin-bottom: 8px;
  }
  .ant-list-item-meta-content {
    width: 100%;
  }
}

.ant-list-item-meta-description {
  > .ant-tag {
    white-space: normal;
    word-break: break-all;
  }
}
