// language-selection show popover bs-popover-bottom .popover {
.popover {
  border-color: rgba(0, 0, 0, 0.1);
  // margin-top: -5px;
  font-size: 1rem;
  > .popover-body {
    padding: 0.5rem 0;
    min-width: 160px;
  }

  &.notification {
    min-width: 300px;
    .list-group {
      > .list-group-item {
        padding: 0.5rem;
        color: $body-color;
      }
    }
  }
}
