$table-background-color: white;
.btn-sort {
  display: inline-block;
  border: none;
  background: transparent;
  padding: 0;
}

.table-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;
  background: $table-background-color;
  .table-content {
    flex: 1;
    overflow: auto;
    table {
      &.table > :not(caption) > * > * {
        border: none;
        padding: 0.65rem 0.5rem;
      }
      thead > tr > th {
        $table-background-color-space: nowrap;
      }
      thead {
        position: sticky;
        top: 0;
        z-index: 0;
        background: $table-background-color;
        border-bottom: 1px solid $border-color;
        height: 50px;
      }
      tbody > tr {
        border-bottom: 1px solid $border-color;
        > td {
          vertical-align: middle;
        }
      }
    }
  }

  .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 50px;
    border-top: 1px solid $border-color;
    ul.pagination {
      margin-bottom: 0;
      > li {
        > a {
          padding: 0.25rem 0.75rem;
          background-color: #fbfbfb;
          margin: 0 0.125rem;
          border-radius: 0.25rem;
          text-decoration: none;
          border: 1px solid gainsboro;
          color: $body-color;
        }
        &.active {
          > a {
            border-color: $primary;
          }
        }
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .item-per-page-input {
      width: 40px;
      display: inline-block;
      height: 24px;
    }
  }
}
