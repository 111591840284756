.app-layout {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: $body-bg;

  nav.navbar-sidebar {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    padding: 0;
  }

  > .app-container {
    width: 100%;
    height: 100%;
    padding-left: $header;
    nav.navbar-header {
      position: sticky;
      top: 0;
      z-index: 1;
      &--sub{
        top: $header;
      }
    }
    > .app-content {
      width: 100%;
      height: calc(100% - #{$header});
    }
  }
}
