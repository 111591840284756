.ant-form {
  .ant-form-item {
    margin-bottom: 16px;
    .ant-form-item-label {
      padding-bottom: 2px;
    }
    &.item-inline {
      flex-direction: row;
      align-items: center;
      gap: 10px;
      position: relative;
      .ant-form-item-control {
        position: unset;
      }
      .ant-form-item-label {
        padding-bottom: 0;
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          display: none;
          content: "";
        }
      }
    }
  }
}
