$header: 50px;
$header-color: #030852;

$border-color: gainsboro;
$hover: #e9ecef;

$all-case: #0B90F0;
$new-case: #fa541c;
$open-24h-case: #2f54eb;
$open-72h-case: #fa8c16;
$resolved-case: #52c41a;

// custom bootstrap
$body-bg: #e8ebf4; // main background
$body-color: #5c6c7f;
$btn-focus-box-shadow: none !important;

$blue: #1890ff;
$primary: #1890ff;
// color
$secondary-color: #888e9c;
// background color
$grey-2: #f9fafb;

$font-family-base: Roboto, "Helvetica Neue", Arial, "Noto Sans",
  "Liberation Sans", sans-serif, system-ui, -apple-system, "Segoe UI",
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$btn-border-radius: 0.5rem;

$input-bg: #fcfcfd;

$dropdown-item-padding-y: 0.375rem;
