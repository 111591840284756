.search-box {
  position: relative;
  .search-box-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 1.25rem;
    opacity: 0.65;
    transform: translateY(-50%);
  }
}
