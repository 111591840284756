.ant-modal-header {
  padding: 2rem 2rem 0.75rem 2rem;
}
.ant-modal-body {
  padding: 1rem 2rem;
}
.ant-modal-footer {
  padding: 0.75rem 2rem 2rem 2rem;
}

.ant-modal.delete-modal {
  max-width: 400px;
  .ant-modal-body {
    padding: 24px;
    display: flex;
    .delete-modal--icon {
      font-size: 24px;
      padding: 5px 12px;
      padding-right: 16px;
      color: #ff7875;
    }
    .delete-modal--content {
      gap: 8px;
      display: flex;
      flex-direction: column;
      > label {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .delete-modal--control {
      justify-content: end;
      display: flex;
      gap: 8px;
    }
  }
}
