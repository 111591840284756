.user-control-dropdown-btn {
  display: inline-flex;
  align-items: center;

  .user-control {
    align-items: center;
    > .user-control-content {
      max-width: 100px;
      margin: 0 0.5rem;
      align-items: flex-start;
      > .text-truncate {
        max-width: inherit;
      }
    }
  }
}
.text-small {
  font-size: 75% !important;
}
.line-height-1 {
  line-height: 1;
}
.gap-2px {
  gap: 2px;
}
