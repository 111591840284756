$band-color: #272d35;

nav.navbar-header {
  height: $header;
  padding: 0 1rem;
  border-bottom: 1px solid $border-color;
  background-color: white;
  &--sub {
    h4 {
      margin: 0;
      font-weight: normal;
    }
  }

  &.add-shadow {
    // 3px 3px 3px -5px rgb(0 0 0 / 18%), 0 5px 5px rgb(0 0 0 / 10%)
    box-shadow: 3px 3px 3px -5px rgba(0, 0, 0, 0.18),
      0 5px 5px rgba(0, 0, 0, 0.1);
  }

  > .navbar-brand {
    color: $band-color;
    font-weight: 500;
    font-size: 20px;
  }
  > .navbar-nav {
    display: flex;
    flex-flow: row nowrap;
    > .navbar-nav-item {
      padding: 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &.bell button > .notification > svg {
        font-size: 2rem;
      }
    }
  }

  &.brand-color {
    background-color: $header-color;
    color: white;
    > .navbar-brand {
      color: white;
    }
  }
}
