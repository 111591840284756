.notification-item-li:not(:last-child) {
  border-bottom: 1px solid gainsboro;
}
.notification-item {
  display: flex;
  flex-flow: row nowrap;
  line-height: normal;
  padding: 5px 0;
  &-icon {
    flex: 0 0 60px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 20px;
    svg.upload {
      color: $primary;
    }
    svg.request-delete {
      color: $danger;
    }
    svg.edit {
      color: $green;
    }
  }
  &-content {

  }
}
