.home-page {
  height: calc(100% - #{$header});
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  .home-search {
    margin: 1rem 0;
    .search-box.home-search-box {
      min-width: 22rem;
    }
    max-height: 120px;
  }
  .table-container {
    height: calc(100% - 120px);
    .table-content {
      table {
        &.table > :not(caption) > * > * {
          &:first-child {
            padding-left: 2rem;
            text-align: left;
          }
          &:last-child {
            padding-right: 2rem;
            width: 150px;
            text-align: right;
          }
        }
      }
    }
    .table-footer {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .card_wrapper{
    margin: 0 -12px;
  }
}
