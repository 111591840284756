.ant-tabs.admin-tabs {
  height: calc(100% - #{$header});
  width: 100%;
  > .ant-tabs-nav {
    padding: 0 1rem;
    background-color: white;
    margin: 0;
    height: $header;
  }
  > .ant-tabs-content-holder {
    height: calc(100% - #{$header});
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-flow: column nowrap;
  }
}
